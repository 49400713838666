import React, { useState } from 'react';

class Cell {
    letter = "-";
    kind = "-";
    lives = "1";
}

const emptyCell = () => ({ ...new Cell(), lives: "-" });

const LevelEditor = () => {
    // const [id, setId] = useState('customid');
    const [title, setTitle] = useState('Custom title');
    const [kind, setKind] = useState('Gravity');
    const [fragole, setFragole] = useState(0);
    const [score, setScore] = useState(0);
    const [words, setWords] = useState(5);
    const [turns, setTurns] = useState(0);
    const [colors, setColors] = useState(false);
    // const [givenLetters, setGivenLetters] = useState([]);
    const [placingLetter, setPlacingLetter] = useState(new Cell());
    const [grid, setGrid] = useState([
        [emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell()],
        [emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell()],
        [emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell()],
        [emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell()],
        [emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell()],
        [emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell()],
        [emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell()],
        [emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell(), emptyCell()],
    ]);

    const handleGridChange = (row, col, char, kind, lives) => {
        const newGrid = [...grid];
        if (JSON.stringify(newGrid[row][col]) === JSON.stringify(emptyCell())) {
            newGrid[row][col] = emptyCell();
            newGrid[row][col].letter = char;
            newGrid[row][col].kind = kind;
            newGrid[row][col].lives = lives;
        } else if (kind === "w") {
            newGrid[row][col] = {
                letter: 'w',
                kind: 'w',
                lives: '1'
            };
        } else {
            newGrid[row][col] = emptyCell();
        }
        setGrid(newGrid);
    };

    const kindMap = {
        '-': "-", 'f': "🍓", 'p': "🏴‍☠️", 'w': "🧱"
    }

    const cycleCellKind = (diff) => {
        const kinds = Object.keys(kindMap);
        const currentIndex = Math.max(kinds.indexOf(placingLetter.kind), 0);
        let nextIndex = kinds.length - 1;
        if ((currentIndex + diff) >= 0) {
            nextIndex = (currentIndex + diff) % kinds.length;
        }
        const nextKind = kinds[nextIndex];
        setPlacingLetter({
            ...placingLetter,
            kind: nextKind,
        });
    };

    const letters = "-abcdefghijklmnopqrstuvwxyz"
    const cycleLetter = (diff) => {
        const currentIndex = Math.max(letters.indexOf(placingLetter.letter), 0);
        let nextIndex = letters.length - 1;
        if ((currentIndex + diff) >= 0) {
            nextIndex = (currentIndex + diff) % letters.length;
        }
        const nextLetter = letters[nextIndex];
        setPlacingLetter({
            ...placingLetter,
            letter: nextLetter,
        });
    };

    const lives = "-123456789"
    const cycleLives = (diff) => {
        const currentIndex = Math.max(lives.indexOf(placingLetter.lives), 0);
        let nextIndex = lives.length - 1;
        if ((currentIndex + diff) >= 0) {
            nextIndex = (currentIndex + diff) % lives.length;
        }
        const nestLives = lives[nextIndex];
        setPlacingLetter({
            ...placingLetter,
            lives: nestLives,
        });
    };

    const generateLevelString = () => {
        let levelString = `id=customlevel;title=${title};kind=${kind};fragole=${fragole};score=${score};words=${words};turns=${turns};`;
        levelString += colors ? "colors=1;" : "colors=0;";
        // if (givenLetters.length > 0) {
        //     levelString += `letters=${givenLetters.join(' ')};`;
        // }
        levelString += "\n";
        levelString += grid.map((row) => row.map(x => {
            let letter = "";
            if (x.kind === "w") {
                letter += "ww1";
            } else {
                letter += x.letter;
                letter += x.kind;
                letter += x.lives;
            }
            return letter;
        }).join(" ")).join('\n')
        console.log(levelString);
        navigator.clipboard.writeText(levelString).then(() => {
            alert("Livello copiato negli appunti");
        });
    };

    return (
        <div>
            <div style={{ fontWeight: "bold", padding: "10px" }}>Level Editor</div>
            <div style={{ padding: "15px" }}>
                <div style={{ marginBottom: "10px", display: "flex" }}>
                    <div style={{ width: "75px" }}>Title</div>
                    <input style={{ marginLeft: "15px", width: "150px" }} type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div style={{ marginBottom: "10px", display: "flex" }}>
                    <div style={{ width: "75px" }}>Kind</div>
                    <select style={{ marginLeft: "15px", width: "150px" }} value={kind} onChange={(e) => setKind(e.target.value)}>
                        <option value="Close">Close</option>
                        <option value="Gravity">Gravity</option>
                        <option value="Snake">Snake</option>
                    </select>
                </div>
                <div style={{ marginBottom: "10px", display: "flex" }}>
                    <div style={{ width: "75px" }}>Fragole</div>
                    <input style={{ marginLeft: "5px", marginRight: "25px", width: "50px" }} type="number" value={fragole} onChange={(e) => setFragole(parseInt(e.target.value))} />
                </div>
                <div style={{ marginBottom: "10px", display: "flex" }}>
                    <div style={{ width: "75px" }}>Score</div>
                    <input style={{ marginLeft: "5px", marginRight: "25px", width: "50px" }} type="number" value={score} onChange={(e) => setScore(parseInt(e.target.value))} />
                </div>
                <div style={{ marginBottom: "10px", display: "flex" }}>
                    <div style={{ width: "75px" }}>Words</div>
                    <input style={{ marginLeft: "5px", marginRight: "25px", width: "50px" }} type="number" value={words} onChange={(e) => setWords(parseInt(e.target.value))} />
                </div>
                <div style={{ marginBottom: "10px", display: "flex" }}>
                    <div style={{ width: "75px" }}>Turns</div>
                    <input style={{ marginLeft: "5px", marginRight: "25px", width: "50px" }} type="number" value={turns} onChange={(e) => setTurns(parseInt(e.target.value))} />
                </div>
                <div style={{ marginBottom: "10px", display: "flex" }}>
                    <div style={{ width: "75px" }}>Colors</div>
                    <input type="checkbox" style={{ marginLeft: "5px", marginRight: "25px", width: "50px" }} value={colors} onChange={(e) => setColors(e.target.value)} />
                </div>
            </div>
            <br />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", fontSize: "x-large", fontWeight: "bold" }}>
                <div style={{ alignItems: "center", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "3em", margin: "1px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div onClick={() => cycleLetter(1)}>⬆️</div>
                        <div>{placingLetter.letter}</div>
                        <div onClick={() => cycleLetter(-1)}>⬇️</div>
                    </div>
                    <div style={{ width: "3em", margin: "1px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div onClick={() => cycleCellKind(1)}>⬆️</div>
                        <div>{kindMap[placingLetter.kind]}</div>
                        <div onClick={() => cycleCellKind(-1)}>⬇️</div>
                    </div>
                    <div style={{ width: "3em", margin: "1px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div onClick={() => cycleLives(1)}>⬆️</div>
                        <div>{placingLetter.lives}</div>
                        <div onClick={() => cycleLives(-1)}>⬇️</div>
                    </div>
                </div>
            </div>
            <br />
            <div style={{ display: "flex", flexDirection: "column", justifyItems: "center", alignItems: "center" }}>
                <h3>Grid Editor</h3>
                {grid.map((row, rowIndex) => (
                    <div key={rowIndex} style={{ display: "flex", flexDirection: "row", transform: "scale(0.8)" }}>
                        {row.map((cell, colIndex) => (
                            <div key={rowIndex + "-" + colIndex} style={{ justifyContent: "center", width: "3em", height: "3em", alignItems: "center", display: "flex", flexDirection: "row" }}
                                onClick={() => handleGridChange(rowIndex, colIndex, placingLetter.letter, placingLetter.kind, placingLetter.lives)}>
                                {cell.letter}
                                {kindMap[cell.kind]}
                                {cell.lives}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <br />
            <button style={{ position: 'initial', margin: '15px' }} onClick={generateLevelString}>Generate Level String</button>
        </div >
    );
};

export default LevelEditor;
